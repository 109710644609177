import { useMemo, useState } from "react";
import { Cell } from "../components";
import { getGrid, useScore, useWordAndMeaning } from "../utils";

export const Grid = ({id, setId, setWordObject, setGameFinished, setStatus, readOnly, wordObject, dataCount, setDataCount}) => {
    const n = 6;  
    const [wordArray, setWordArray] = useState([]);
    const [count, setCount] = useState(1);
    // const [dataCount, setDataCount] = useState(0);
    // const [addressArray, setAddressArray] = useState(null);
    
    useWordAndMeaning(id, setId, wordArray, setWordArray, setWordObject, count, setCount, dataCount, setDataCount, setStatus);
    
    const grid = useMemo(() => getGrid(n), [n]);

    let cellCount = 0;

    for(let i = 0; i < n; i++){
        for(let j = 0; j < n; j++){
            if(grid[i][j] === ''){
                cellCount += 1;
            }
        }
    }

    if(cellCount === 0){
        setGameFinished(true);
    }

  return (
    <div className="w-full flex justify-center">
        <div className="rounded-xl p-1 bg-purple-300 shadow shadow-slate-500">
        {
            Array(n).fill().map((a, i) => (
                <div className='flex' key={i}>
                   { 
                    Array(n).fill().map((b, j) =>(
                        <div className="p-0.5" key={j}>
                            <Cell 
                                i={i} 
                                j={j} 
                                grid={grid} 
                                n={n}  
                                setWordArray={setWordArray}
                                setCount={setCount}
                                readOnly={readOnly}
                                wordObject={wordObject}
                            />
                        </div>
                    ))
                    }
                </div>
            ))
        }
        </div>
    </div>
  )
}
