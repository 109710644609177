import { useContext, useRef } from 'react';
import {ScoreContext} from '../App';

export const PlayerCard = ({pid, id, status, activePlayer, scoreAddedOpacity, scoreAdded}) => {
 const {
  player1Name, setPlayer1Name, player1Score, player2Name, setPlayer2Name, player2Score
} = useContext(ScoreContext);

 const nameRef = useRef();
 const btnRef = useRef();
 
 const handleSubmit = (pid) => {  
  { pid === 1 
    ? setPlayer1Name(nameRef.current.value || 'Player 1') 
    : setPlayer2Name(nameRef.current.value || 'Player 2') }
  
  nameRef.current.hidden = 'hidden';
  btnRef.current.hidden = 'hidden';
 } 

  return (
    <div className={`${pid === activePlayer ? 'opacity-100' : 'opacity-40'}  mt-3 p-2 w-5/12 bg-red-400 rounded border-0 font-bold text-cyan-700 font-bold transition-all duration-500 delay-[100ms]`}>
        <div className={`player-card relative opacity-${scoreAddedOpacity} text-4xl top-5 shadow-lg left-12 w-10 h-0 transition-all duration-1000 scale-125 ease-in-out text-emerald-700 font-semibold`}>
          {(id === pid) ? (`${scoreAdded ? `+${scoreAdded}` : ''}`) : ''}
        </div>

        <input 
          type="text" 
          placeholder={`Player ${pid} name`} 
          ref={nameRef} required 
          className='rounded p-1 w-full'
        />

        <div className='font-serif text-2xl text-blue-700'>{pid === 1 ? player1Name : player2Name}</div>

        <button 
          onClick={() => handleSubmit(pid)} 
          ref={btnRef} 
          className='mt-2 text-red-200 bg-red-600 rounded-2xl px-3 py-1 shadow hover:shadow-slate-100 hover:text-red-100'
        >
          Submit
        </button>

        <h2 className='text-3xl text-fuchsia-900'>
          {pid === 1 ? (player1Score) : (player2Score)}
        </h2> 
    </div>
  )
}
