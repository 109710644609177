import { useEffect, useState } from "react";
import { useDictionary } from "./useDictionary";

export const useWordAndMeaning = (
    id, setId, wordArray, setWordArray, setWordObject, count, setCount, dataCount, setDataCount, setStatus
) => {
    const [checkWord, setCheckWord] = useState('');
    const [checkWordInDictionary, setCheckWordInDictionary] = useState('');

    const wordInDictionary = useDictionary(checkWordInDictionary, dataCount, setDataCount, setStatus);

    useEffect(() =>{        
        setCheckWord(wordArray[0]);        
    }, [wordArray]);  

    useEffect(() => {
        setCheckWordInDictionary(checkWord);
    }, [checkWord]); 

    useEffect(() => {        
        if(wordInDictionary.valid == true){
            setCount(1);
            setWordObject(wordInDictionary);
            setId( id === 1 ? id = 2 : id = 1);
            setWordArray([]);
        }
        else{            
            if(count < wordArray.length){
                setCheckWordInDictionary(wordArray[count]);
                setCount(count+1);
                setWordObject('');
            }
            else if(wordArray.length && count === wordArray.length){
                setId( id === 1 ? id = 2 : id = 1);
                setWordObject('');
                setWordArray([]);
            }
        }
    }, [wordInDictionary]);
}