import { createContext, useState } from "react";
import { Game } from "./pages/Game";

export const ScoreContext = createContext();

function App() {
  const [player1Name, setPlayer1Name] = useState('');
  const [player1Score, setPlayer1Score] = useState(0);
  const [player2Name, setPlayer2Name] = useState('');
  const [player2Score, setPlayer2Score] = useState(0);
  const [wordStore, setWordStore] = useState([]);

  return (
    <ScoreContext.Provider 
      value = {{player1Name, setPlayer1Name, player1Score, setPlayer1Score, player2Name, setPlayer2Name, player2Score, setPlayer2Score, wordStore, setWordStore}}
    >
      <div className="bg-rose-900 w-screen h-screen">
        <main>
          <Game/>
        </main>
      </div>
    </ScoreContext.Provider>
  );
}

export default App;