import { useEffect, useState, useContext } from 'react';
import { ScoreContext } from '../App';

export const useDictionary = (word, dataCount, setDataCount, setStatus) => {
  const [data, setData] = useState({});
  const {wordStore, setWordStore} = useContext(ScoreContext);

  const wordStoreSet = [...new Set(wordStore)];

  const url = `https://dictionary-by-api-ninjas.p.rapidapi.com/v1/dictionary?word=${word?.replace(/[0-9]/g, '')}`;

  const options = {
          method: 'GET',
          headers: {
            'x-rapidapi-key': `${process.env.REACT_APP_API_KEY}`,
            'x-rapidapi-host': 'dictionary-by-api-ninjas.p.rapidapi.com'
          }
        };
  
        useEffect(() => {
        fetch(url, options)
          .then(res => res.json())
          .then(data => {
            setData({...data, wordWithAddress: word}); 
          })
          .then(() => setDataCount(dataCount + 1))
          .then(() => {console.log('DATA Count: ', dataCount)})
          .then(() => setWordStore([...wordStoreSet, word?.replace(/[0-9]/g, '')])) // eslint-disable-next-line
        },[word]); 

        return data;
}









// export const useDictionary = (word, dataCount, setDataCount, setStatus) => {
//   const [data, setData] = useState({});
//   const {wordStore, setWordStore} = useContext(ScoreContext);
//   const wordStoreSet = [...new Set(wordStore)];
  
//   useEffect(() => {
//     fetch(`http://localhost:3001/words`)
//       .then(res => res.json())
//       .then(data => {
//         setData({...(data.find(d => d.id == word?.replace(/[0-9]/g, '')) || data.find(d => d.id == 'wrong_word')), wordWithAddress: word}); 
//       })
//       .then(() => {console.log('DATA RENDERING... word: ', word)})
//       .then(() => setDataCount(dataCount + 1))
//       .then(() => {console.log('DATA Count: ', dataCount)})
//       .then(() => setWordStore([...wordStoreSet, word?.replace(/[0-9]/g, '')]))
//       .catch(err => { console.log('err', err); });
//   },[word]);
  
//   return data;
// }








// export const useDictionary = (word, dataCount, setDataCount, setStatus) => {
//   const [data, setData] = useState({});
//   const {wordStore, setWordStore} = useContext(ScoreContext);

//   const wordStoreSet = [...new Set(wordStore)];

//   const url = `https://linguatoolbox.p.rapidapi.com/${word?.replace(/[0-9]/g, '')}`;

//   const options = {
//           method: 'GET',
//           headers: {
//             'x-rapidapi-key': `${process.env.REACT_APP_API_KEY}`,
//             'x-rapidapi-host': 'linguatoolbox.p.rapidapi.com'
//           }
//         };
  
//         useEffect(() => {
//         fetch(url, options)
//           .then(res => res.json())
//           .then(data => {setData(data); setStatus("success");})
//           // .then(console.log('data', data))
//           .then(() => setWordStore([...wordStoreSet, word?.replace(/[0-9]/g, '')])) // eslint-disable-next-line
//           // .catch(err => {
//           //   //         console.log('err', err);
//           //   //         // setError(error);
//           //           setStatus("error");
//           // });
//         },[word]); 

//         return data;
// }




