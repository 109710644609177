import { useEffect, useContext } from "react";
import sleep from 'sleep-promise';

import { ScoreContext } from "../App";

export const useScore = (
  word, id, status, setStatus, setReadOnly, setActivePlayer, setScoreAddedOpacity
) => {
  const {player1Score, setPlayer1Score, player2Score, setPlayer2Score} = useContext(ScoreContext);
  // const [status, setStatus] = useState("idle");
  // setStatus('loading');
  
  useEffect(() => { 
    setStatus('loading');

    (async () => {
      setScoreAddedOpacity(100);

      await sleep(2000);

      setScoreAddedOpacity(0);

      if(id === 1){
        let i = 0;
        // setStatus('loading');
        setReadOnly(true);
          const timer = setInterval(() => { 
            if(word?.length === (i || 0 || undefined)){
              setStatus('success');
              setActivePlayer(2);
              setReadOnly(false);
              clearInterval(timer);
            }
          
            else{
              i++;
              setPlayer1Score(parseInt(player1Score + i));
            }
  
        }, 400);
      }
      else if (id === 2){
        let i = 0;
        // setStatus('loading');
        setReadOnly(true);
          const timer = setInterval(() => { 
            if(word?.length === (i || 0 || undefined)){
              setStatus('success');
              setActivePlayer(1);
              setReadOnly(false);
              clearInterval(timer);
            }
          
            else{
              i++;
              setPlayer2Score(parseInt(player2Score + i));
            }
        }, 400);
      }
    })();
  }, [id]);
}
