import { useContext, useEffect, useRef } from 'react';
import { constructWord } from '../utils';
import { ScoreContext } from '../App';

export const Cell = ({
  i, j, grid, n, setWordArray, setCount, readOnly, wordObject
}) => {  
        const {wordStore} = useContext(ScoreContext);
        const cellRef = useRef();       
        let addressArray;

        useEffect(() => {
          document.querySelectorAll('input').forEach(inp => inp.style.backgroundColor = 'white');
          addressArray = wordObject.wordWithAddress?.replace(/[a-z]/g, ' ').trimStart().split(' ');

           document.querySelectorAll('input').forEach(inp => {
            addressArray?.map(addr => { 
              if(inp.id === `cell${addr}`){
              document.querySelector(`#${inp.id}`).style.backgroundColor = 'yellow';
              }
            })
           });          
        }, [wordObject]);
        
        const handleChange = (e) => {
          e.target.disabled = 'disabled';
          setCount(1);

          const currentWordAddress = e.target.value.concat(i).concat(j).toLowerCase();
          console.log('current word address', currentWordAddress);
          
          grid[i][j] = e.target.value;

          const wordArray = constructWord(grid, i, j, n, wordStore, currentWordAddress);

          setWordArray(wordArray);       
        };

  return (
    <input
        ref={cellRef}
        id={`cell${i}${j}`}
        className={`cell${i}${j} rounded-2xl shadow-xl outline-1 outline-purple-300 w-12 h-11 bg-white text-blue-800 cursor-pointer font-bold flex text-center pb-1 text-3xl shadow-sm`}
        onChange={handleChange}
        readOnly={readOnly}
        autoComplete="off"
    >
    </input>
  )
}
