import { ScoreContext } from "../App";
import { useContext } from "react";

export const WinnerCard = ({gameFinished}) => {
  const {player1Name, player1Score, player2Name, player2Score} = useContext(ScoreContext);
    
  return (
    gameFinished && 
    <div className="transition-all duration-500 delay-[10000ms] w-full h-100 absolute w-full justify-items-center text-center top-40">
        {   
          <div 
            id='winner' 
            className="game-finish animate-wiggle opacity-0 scale-125 transition-all duration-1000 text-4xl text-green-100 font-bold w-72"
          >
            {`${
              (player1Score > player2Score) 
              ? `'${player1Name}' won the match` 
              : ((player1Score < player2Score) ? `${player2Name} won the match` : 'Match Drawn')
            }`}
          </div>
        }
    </div>
  )
}
