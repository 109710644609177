export const WordMeaningCard = ({ wordObject, status, dataCount }) => {    
  return (
   <div className="">{
      wordObject.word  
      ? <p>
          <span className="text-yellow-200 text-xl font-semibold">{`${wordObject.word}`}</span>
          <span>
            {` : ${
              (wordObject.definition?.split('1.')[1]?.split('2.')[0]) 
              ? (wordObject.definition.split('1.')[1].split('2.')[0]) 
              : (wordObject.definition)
            }`}
          </span>
        </p>
      : dataCount > 1 ? (status === 'loading' ? 'Finding word...' : 'No points gained') : 'Start the game'
    }
    </div>
  )
}
