import {aArray, bArray, cArray, dArray, eArray, fArray, gArray, hArray, iArray, jArray, kArray, lArray, mArray, nArray, oArray, pArray, qArray, rArray, sArray, tArray, uArray, vArray, wArray, xArray, yArray, zArray} from './allWords';

export const constructWord = (
    grid, i, j, n, wordStore, currentWordAddress
) => {
    // for horizontal (Row)
    let rowWordArray = [];
    let l = j, m = j; do{ l = l - 1; } while(l>=0 && grid[i][l] !== '');
    const col_start = l + 1; do{ m = m + 1; } while(m<n && grid[i][m] !== '');
    const col_end = m - 1; let constructedRowWord = ''; let constructedRowWordAddress = {};
    for(let k = col_start; k <= col_end; k++){ 
        constructedRowWordAddress[grid[i][k].toLowerCase().concat(i).concat(k)] = {
            constructdeRowAlphabet: grid[i][k].toLowerCase(), 
            constructedRowNumber: i.toString() + k.toString()
        };      
    }

    console.log(`
    `);
    
    for(let a = 0; a < Object.keys(constructedRowWordAddress).length; a++){
        for(let b = Object.keys(constructedRowWordAddress).length-1; b >= a; b--){
            let rowWord='';
            for(let c = a; c <= b; c++){ 
                rowWord = rowWord.concat(Object.keys(constructedRowWordAddress)[c]); 
            }
            rowWordArray.push(rowWord);
            rowWordArray = rowWordArray.sort((a,b) => b.length - a.length);
        }  
    }    

    for(let d = Object.keys(constructedRowWordAddress).length-1; d >= 0; d--){
        for(let e = 0; e < Object.keys(constructedRowWordAddress).length-1; e++){
            let rowWord='';
            for(let f = d; f >= e; f--){ 
                rowWord = rowWord.concat(Object.keys(constructedRowWordAddress)[f]); 
            }
            rowWordArray.push(rowWord);   
            rowWordArray = rowWordArray.sort((a,b) => b.length - a.length);
        }  
    }

    //for vertical (Column)
    let colWordArray = [];
    let p = i, q = i; do{ p = p - 1; } while(p >= 0 && grid[p][j] !== '');
    const row_start = p + 1; do{ q = q + 1; } while(q < n && grid[q][j] !== '');
    const row_end = q - 1; let constructedColWord = ''; let constructedColWordAddress = {};
    for(let k = row_start; k <= row_end; k++){ 
        constructedColWordAddress[grid[k][j].toLowerCase().concat(k).concat(j)] = {
            constructdeColAlphabet: grid[k][j].toLowerCase(), 
            constructedColNumber: k.toString() + j.toString()
        }; 
    }


    for(let a = 0; a < Object.keys(constructedColWordAddress).length; a++){
        for(let b = Object.keys(constructedColWordAddress).length-1; b >= a; b--){
            let colWord='';
            for(let c = a; c <= b; c++){ colWord = colWord.concat(Object.keys(constructedColWordAddress)[c]); }
            colWordArray.push(colWord);
            colWordArray = colWordArray.sort((a,b) => b.length - a.length);
        }  
    }

    for(let d = Object.keys(constructedColWordAddress).length-1; d >= 0; d--){
        for(let e = 0; e < Object.keys(constructedColWordAddress).length-1; e++){
            let colWord='';
            for(let f = d; f >= e; f--){ 
                colWord = colWord.concat(Object.keys(constructedColWordAddress)[f]); 
            }
            colWordArray.push(colWord);
            colWordArray = colWordArray.sort((a,b) => b.length - a.length);
        }  
    }

    //Combined Row and Column Words
    
    let wordArray = [...(new Set(rowWordArray.concat(colWordArray)))].filter(w => w.replace(/[0-9]/g, '') !== wordStore.find(ws => w.replace(/[0-9]/g, '') === ws)).sort((a,b) => b.length - a.length);

    if(wordArray.length > 1){
        wordArray = wordArray.filter(w => w.replace(/[0-9]/g, '').length !== 1);
        if(wordArray.length === 0){ wordArray.push(''); }
    } 
    
    else {
        wordArray.pop();
    }

    wordArray = wordArray.filter(wA => wA.includes(currentWordAddress) );

    if(wordArray){
        wordArray = wordArray.filter(wA =>{ 
            if(wA.replace(/[0-9]/g, '').startsWith('a')){
                return wA.replace(/[0-9]/g, '') === aArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('b')){
                return wA.replace(/[0-9]/g, '') === bArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('c')){
                return wA.replace(/[0-9]/g, '') === cArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('d')){
                return wA.replace(/[0-9]/g, '') === dArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('e')){
                return wA.replace(/[0-9]/g, '') === eArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('f')){
                return wA.replace(/[0-9]/g, '') === fArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('g')){
                return wA.replace(/[0-9]/g, '') === gArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('h')){
                return wA.replace(/[0-9]/g, '') === hArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('i')){
                return wA.replace(/[0-9]/g, '') === iArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('j')){
                return wA.replace(/[0-9]/g, '') === jArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('k')){
                return wA.replace(/[0-9]/g, '') === kArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('l')){
                return wA.replace(/[0-9]/g, '') === lArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('m')){
                return wA.replace(/[0-9]/g, '') === mArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('n')){
                return wA.replace(/[0-9]/g, '') === nArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('o')){
                return wA.replace(/[0-9]/g, '') === oArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('p')){
                return wA.replace(/[0-9]/g, '') === pArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('q')){
                return wA.replace(/[0-9]/g, '') === qArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('r')){
                return wA.replace(/[0-9]/g, '') === rArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('s')){
                return wA.replace(/[0-9]/g, '') === sArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('t')){
                return wA.replace(/[0-9]/g, '') === tArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('u')){
                return wA.replace(/[0-9]/g, '') === uArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('v')){
                return wA.replace(/[0-9]/g, '') === vArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('w')){
                return wA.replace(/[0-9]/g, '') === wArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('x')){
                return wA.replace(/[0-9]/g, '') === xArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('y')){
                return wA.replace(/[0-9]/g, '') === yArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
            else if(wA.replace(/[0-9]/g, '').startsWith('z')){
                return wA.replace(/[0-9]/g, '') === zArray[wA.replace(/[0-9]/g, '').length-2].find(a => a === wA.replace(/[0-9]/g, ''))
            }
        });
    };

    if(!wordArray.length){
        wordArray.push('');
    }

    console.log('wordArray', wordArray);    
    
    return wordArray;
}