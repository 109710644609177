export const getGrid = (n) => {
    const grid = [];
  
    Array(n).fill().map((a, i) => {
      grid[i] = [];
  
      Array(n).fill().map((b, j) => grid[i][j] = '')
    });

  return grid;
}
