import { useState } from "react";
import { Grid, PlayerCard, WordMeaningCard, WinnerCard } from "../components";
import { useScore } from "../utils";

export const Game = () => {
  const [wordObject, setWordObject] = useState({});
  const [id, setId] = useState(2);
  const [gameFinished, setGameFinished] = useState(false);
  const [status, setStatus] = useState("idle");
  const [readOnly, setReadOnly] = useState(false);
  const [activePlayer, setActivePlayer] = useState(1);
  const [scoreAddedOpacity, setScoreAddedOpacity] = useState(10);  
  const [dataCount, setDataCount] = useState(0);

  useScore(wordObject.word, id, status, setStatus, setReadOnly, setActivePlayer, setScoreAddedOpacity);

  return (
    <main>
        <section className='flex justify-center items-center text-white'>
          <div className='text-4xl mt-2 text-yellow-400 font-bold'>Vocabulary Game</div>
          <WinnerCard gameFinished={gameFinished} />
        </section>

        <section className='flex flex-wrap justify-around mt-3'>
          <Grid 
            id={id} setId={setId} setWordObject={setWordObject} setGameFinished={setGameFinished} 
            setStatus={setStatus} readOnly={readOnly} wordObject={wordObject} dataCount={dataCount} setDataCount={setDataCount}
          />
          <PlayerCard pid={1} id={id} status={status} activePlayer={activePlayer} scoreAddedOpacity={scoreAddedOpacity} scoreAdded={wordObject.word?.length}/>
          <PlayerCard pid={2} id={id} status={status} activePlayer={activePlayer} scoreAddedOpacity={scoreAddedOpacity} scoreAdded={wordObject.word?.length}/>
        </section>

        <section className='flex justify-center items-center text-white bg-slate-800 mt-3 shadow-slate-700 overflow-auto px-2 py-2'>
          <WordMeaningCard wordObject={wordObject} status={status} dataCount={dataCount}/>
        </section>
    </main>
  )
}
